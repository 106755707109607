<template>
  <div class="intra-news-list">
    <div class="intra-news-list-top">
      <SectionTitle
        :title="title_component.title"
        :subtitle="title_component.subtitle"
      />
      <div class="intra-news-list-top-searcher">
        <div class="intra-news-list-top-searcher-container">
          <input
            type="text"
            placeholder="Buscar noticia"
            v-model="search_news"
          />
          <div class="icon">
            <font-awesome-icon icon="search"></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="intra-news-list-container">
      <!-- Lista de noticias -->
      <div class="list" v-if="news_list_search.length == 0 && no_results == ''">
        <div class="list-row" v-for="(x, k) in rowCount(false)" :key="k">
          <div class="item" v-for="(n, i) in itemCountInRow(x, false)" :key="i">
            <div class="item-img">
              <img :src="n.url_imagen_principal" alt="" />
            </div>
            <div class="item-date" v-if="futureNew(n.fecha_noticia)">
              <p>
                Esta noticia se publicará <br />el
                <strong>{{ dateNewFormat(n.fecha_noticia) }}</strong>
              </p>
            </div>
            <div class="item-icons">
              <span
                class="watch"
                content="Ver noticia"
                v-tippy="{ arrow: true }"
                @click="(noticia_selected = n), (open_modal = true)"
              >
                <font-awesome-icon icon="eye"></font-awesome-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Resultado de busqueda -->
      <div
        class="list search-result"
        v-if="news_list_search.length > 0 && no_results == ''"
      >
        <div class="list-row" v-for="(x, k) in rowCount(true)" :key="k">
          <div class="item" v-for="(n, i) in itemCountInRow(x, true)" :key="i">
            <div class="item-img">
              <img :src="n.url_imagen_principal" alt="" />
            </div>
            <div class="item-icons">
              <span
                class="watch"
                content="Ver noticia"
                v-tippy="{ arrow: true }"
                @click="(noticia_selected = n), (open_modal = true)"
              >
                <font-awesome-icon icon="eye"></font-awesome-icon>
              </span>
              <span
                class="edit"
                content="Editar noticia"
                v-tippy="{ arrow: true }"
              >
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </span>
              <span
                class="delete"
                content="Eliminar noticia"
                v-tippy="{ arrow: true }"
              >
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- sin resultados -->
      <div class="no-results" v-if="no_results !== ''">
        <div class="back" @click="getBack">
          <font-awesome-icon icon="angle-left"></font-awesome-icon>
          Volver
        </div>
        {{ no_results }}
      </div>
    </div>
    <div class="modal" v-if="open_modal">
      <div class="modal-container">
        <div class="modal-container-top">
          <div class="close" @click="open_modal = false">
            <font-awesome-icon icon="times"></font-awesome-icon>
          </div>
          <div class="top-container">
            <img :src="noticia_selected.url_imagen_principal" alt="" />
            <div class="title">
              {{ noticia_selected.titulo_noticia }}
            </div>
          </div>
        </div>
        <div class="modal-container-main">
          <div class="contenido">
            <div v-for="(content, k) in noticia_selected.contenido" :key="k">
              <div
                class="text"
                v-if="
                  content.texto !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '1'
                "
                v-html="content.texto"
              ></div>
              <div
                class="image"
                v-if="
                  content.url_imagen !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '2'
                "
              >
                <img :src="content.url_imagen" alt="" />
              </div>
              <div
                class="video"
                v-if="
                  content.url_video !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '3'
                "
              >
                <video
                  src="content.url_video"
                  autoplay
                  controls
                  alt="Video"
                ></video>
              </div>
              <div
                class="document"
                v-if="
                  content.url_documento !== null &&
                  content.tipo_contenido[0].id_tipo_contenido == '4'
                "
              >
                <a :href="content.url_documento" target="_blank">
                  Ver documento
                  <span>
                    <font-awesome-icon icon="link"></font-awesome-icon>
                  </span>
                </a>
              </div>
              <div
                class="user"
                v-if="
                  content.usuario.length > 0 &&
                  content.tipo_contenido[0].id_tipo_contenido == '5'
                "
              >
                <div
                  class="detail"
                  v-for="(user, y) in content.usuario"
                  :key="y"
                >
                  Por:
                  {{ user.primer_nombre + "  " + user.apellido_paterno }}
                  <span>
                    <font-awesome-icon icon="user"></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
    <!-- Modal pregunta -->
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <!--  Modal que muestra el estado de la accion (exito o error) -->
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
  </div>
</template>

<script>
import SectionTitle from "../Section/SectionTitle.vue";
import { mapState, mapActions } from "vuex";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import { DELETE_NOTICIA } from "@/apollo/mutations";

export default {
  components: { SectionTitle, Spinner, Question, Status },
  data() {
    return {
      title_component: {
        title: "Noticias",
        subtitle: "Noticias publicadas",
      },
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_categoria: "0",
      especial_categoria: "1",
      open_modal: false,
      noticia_selected: {},
      show_spinner: false,
      news_list_search: [],
      no_results: "",
      search_news: "",
      question_modal_msg: "¿Deseas eliminiar esta noticia?",
      open_question_modal: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
    };
  },
  created() {
    this.news_list.forEach((news) => {
      if (this.futureNew(news.fecha_noticia) == false) {
        this.news_list_filter.push(news);
      }
    });
  },

  mounted() {
    var ids = {
      empresa: this.id_empresa,
      categoria: this.id_categoria,
      especial: this.especial_categoria,
    };
    this.getNewsList(ids);
    //  console.log(this.news_list);
  },
  watch: {
    search_news: function () {
      if (this.search_news.length > 2) {
        this.searchNews();
      } else {
        this.news_list_search = [];
        this.no_results = "";
      }
    },
    noticia_selected: function () {
      console.log(this.noticia_selected);
    },
    news_list_search: function () {
      console.log(this.news_list_search);
    },
  },
  computed: {
    ...mapState("newsModule", ["news_user_list"]),
  },
  methods: {
    ...mapActions("newsModule", ["getNewsList", "deleteNewsAction"]),
    // Funcion para mostrar array de noticias en hileras/row de 4 elementos
    rowCount(search) {
      //  console.log(search);
      if (search) {
        return Math.ceil(this.news_list_search.length / 4);
      }
      return Math.ceil(this.news_user_list.length / 4);
    },
    // Funcion para mostrar array de noticias en hileras/row de 4 elementos
    itemCountInRow(index, search) {
      if (search) {
        return this.news_list_search.slice((index - 1) * 4, index * 4);
      }
      return this.news_user_list.slice((index - 1) * 4, index * 4);
    },
    // Buscador de noticias
    searchNews() {
      var search = this.search_news.toLowerCase();
      let search_result = this.news_user_list.filter((news) =>
        news.titulo_noticia.toLowerCase().includes(search)
      );
      if (search_result.length > 0) {
        this.news_list_search = [];
        search_result.forEach((news) => {
          if (this.futureNew(news.fecha_noticia) == false) {
            this.news_list_search.push(news);
          }
        });
      } else {
        this.no_results = this.$noResults;
      }
    },
    // Volver despues de la busqueda
    getBack() {
      this.no_results = "";
      this.news_list_search = [];
      this.noticia_selected = {};
      this.search_news = "";
    },
    // Funcion para "cancelar" la pregunta del Modal Question
    cancelQuestion() {
      this.open_question_modal = false;
    },
    // Funcion para "aceptar" la pregunta del modal Question (eliminar usuario)
    acceptQuestion() {
      this.open_question_modal = false;
      this.show_spinner = true;
      //  console.log(this.noticia_selected);
      this.$apollo
        .mutate({
          mutation: DELETE_NOTICIA,
          variables: {
            id_empresa_fk: this.id_empresa,
            id_noticia: this.noticia_selected.id_noticia,
          },
        })
        .then((res) => {
          var resp = res.data.eliminarNoticia;
          //  console.log(resp);
          this.show_spinner = false;
          if (resp.id_noticia) {
            this.open_question_modal = false;
            this.modal_status_msg = "Noticia eliminada con éxito";
            this.open_modal_status = true;
            this.deleteNewsAction(resp.id_noticia);
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.show_spinner = false;
          this.modal_status_msg =
            "La noticia no ha sido eliminada, intenta nuevamente";
          this.open_modal_status = true;
          this.modal_status = false;
        });
    },
    // Funcion para cerrar modal de status
    closeStatus() {
      this.open_modal_status = false;
    },
    //Fecha futura
    futureNew(date) {
      var day = new Date(date);
      var toDay = new Date();
      return day > toDay;
    },
    //Formatear fecha
    dateNewFormat(date) {
      var day = new Date(date);
      return `${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`;
    },
  },
};
</script>
